import {
	type PayloadAction,
	createAsyncThunk,
	createSlice,
} from "@reduxjs/toolkit";
import {
	JOBS_FEATURE_KEY,
	NEW_JOB_STATE,
} from "../../../../common/models/src/lib/constants/job.constants";
import { JobStatus } from "../../../../common/models/src/lib/enums/job.enum";
import type { CelerumQueryParams } from "../../../../common/models/src/lib/interfaces/celerum-query-params.interface";
import type { CelerumResponse } from "../../../../common/models/src/lib/interfaces/celerum-response.interface";
import type { IDocument } from "../../../../common/models/src/lib/interfaces/document.interface";
import type {
	IJobDetails,
	IJobRequestDto,
	IJobResponseDto,
	JobsState,
} from "../../../../common/models/src/lib/interfaces/job.interface";
import type { ILocation } from "../../../../common/models/src/lib/interfaces/location.interface";
import {
	cancelJob,
	changeJobStatus,
	createJob,
	deleteJob,
	duplicateJob,
	fetchJobById,
	fetchJobs,
	forceComplete,
	generateFileFront,
	partialUpdateJob,
	pauseJob,
	resumeJob,
	updateJob,
} from "./jobs-data-access";

const initialState: JobsState = {
	data: [],
	job: undefined,
	total: 0,
	aggregateResults: null,
	loading: { grid: false, job: false, attachments: false, status: false },
	errors: null,
};

export const fetchJobsAction = createAsyncThunk(
	"jobs/fetchJobs",
	async (params: CelerumQueryParams) => {
		return fetchJobs(params);
	},
);

export const fetchJobByIdAction = createAsyncThunk(
	"jobs/fetchJobById",
	(id: number) => {
		return fetchJobById(id);
	},
);

export const fetchJobStatusAction = createAsyncThunk(
	"jobs/fetchJobStatus",
	(id: number) => {
		return fetchJobById(id);
	},
);

export const createJobAction = createAsyncThunk(
	"jobs/createJob",
	(job: IJobRequestDto) => {
		return createJob(job);
	},
);

export const updateJobAction = createAsyncThunk(
	"jobs/updateJob",
	(job: IJobDetails) => {
		return updateJob(job);
	},
);

export const partialUpdateJobAction = createAsyncThunk(
	"jobs/updateJob",
	(job: IJobDetails) => {
		return partialUpdateJob(job);
	},
);

export const duplicateJobAction = createAsyncThunk(
	"jobs/duplicateJob",
	(job: IJobDetails) => {
		return duplicateJob(job, job.id);
	},
);

export const changeJobStatusAction = createAsyncThunk(
	"jobs/changeJobStatus",
	({ id, status }: { id: number; status: JobStatus }) => {
		return changeJobStatus(id, status);
	},
);

export const pauseJobAction = createAsyncThunk(
	"jobs/pauseJob",
	(jobId: number) => {
		return pauseJob(jobId);
	},
);

export const resumeJobAction = createAsyncThunk(
	"jobs/resumeJob",
	(jobId: number) => {
		return resumeJob(jobId);
	},
);

export const cancelJobAction = createAsyncThunk(
	"jobs/cancelJob",
	(jobId: number) => {
		return cancelJob(jobId);
	},
);

export const deleteJobAction = createAsyncThunk(
	"jobs/deleteJob",
	(jobId: number) => {
		return deleteJob(jobId);
	},
);

export const forceCompleteAction = createAsyncThunk(
	"jobs/forceComplete",
	(jobId: number) => {
		return forceComplete(jobId);
	},
);

export const generateFileFrontAction = createAsyncThunk(
	"jobs/generateFileFront",
	(jobId: number) => {
		return generateFileFront(jobId);
	},
);

const jobsSlice = createSlice({
	name: JOBS_FEATURE_KEY,
	initialState: initialState,
	reducers: {
		clearJobsAction: (state: JobsState) => {
			state.data = initialState.data;
		},
		attachDocumentsToJobAction: (
			state: JobsState,
			action: PayloadAction<IDocument[]>,
		) => {
			if (state.job) {
				state.job.documents = [
					...state.job.documents,
					...(action.payload as IDocument[]),
				];
			}
		},
		deleteDocumentFromJobAction: (
			state: JobsState,
			action: PayloadAction<number | string | null>,
		) => {
			if (state.job) {
				state.job.documents = [
					...state.job.documents.filter(
						(document) => document.id !== action.payload,
					),
				];
			}
		},
		clearJobAction: (state: JobsState) => {
			state.job = initialState.job;
		},
	},
	extraReducers: (builder) => {
		builder
			/** Pending */
			.addCase(fetchJobsAction.pending, (state: JobsState) => {
				state.loading.grid = true;
			})
			.addCase(fetchJobByIdAction.pending, (state: JobsState) => {
				state.loading.job = true;
			})
			.addCase(fetchJobStatusAction.pending, (state: JobsState) => {
				state.loading.status = true;
			})
			.addCase(createJobAction.pending, (state: JobsState) => {
				state.loading.grid = true;
			})
			.addCase(updateJobAction.pending, (state: JobsState) => {
				state.loading.grid = true;
			})
			.addCase(duplicateJobAction.pending, (state: JobsState) => {
				state.loading.grid = true;
			})
			.addCase(changeJobStatusAction.pending, (state: JobsState) => {
				if (state.job) {
					state.loading.job = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(pauseJobAction.pending, (state: JobsState) => {
				if (state.job) {
					state.loading.job = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(resumeJobAction.pending, (state: JobsState) => {
				if (state.job) {
					state.loading.job = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(cancelJobAction.pending, (state: JobsState) => {
				if (state.job) {
					state.loading.job = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(deleteJobAction.pending, (state: JobsState) => {
				state.loading.grid = true;
			})
			.addCase(forceCompleteAction.pending, (state: JobsState) => {
				if (state.job) {
					state.loading.job = true;
				} else {
					state.loading.grid = true;
				}
			})
			.addCase(generateFileFrontAction.pending, (state: JobsState) => {
				if (state.job) {
					state.loading.job = true;
				} else {
					state.loading.grid = true;
				}
			})
			/** Fulfilled */
			.addCase(
				fetchJobsAction.fulfilled,
				(state: JobsState, action: PayloadAction<CelerumResponse>) => {
					const { data, total, aggregateResults, page, pageSize } =
						action.payload;
					const jobs = data as IJobResponseDto[];

					/** Check if the data is already fetched
					 * If it is, just update the data
					 * If it is not, fill the array with NEW_JOB_STATE
					 */
					const newJobs =
						state.data.length === total
							? [...state.data]
							: new Array(total).fill(NEW_JOB_STATE);

					/** Fill newJobs with the fetched data */
					jobs.forEach((job, i) => {
						if (page && pageSize) {
							const index = (page - 1) * pageSize + i;
							newJobs[index] = {
								...newJobs[index],
								...job,
							};
						}
					});

					state.total = total;
					state.data = newJobs;
					state.aggregateResults = aggregateResults;
					state.loading.grid = false;
				},
			)
			.addCase(
				fetchJobByIdAction.fulfilled,
				(state: JobsState, action: PayloadAction<IJobDetails>) => {
					const { startLocation, endLocation, ...jobDetails } =
						action.payload as IJobDetails;

					const start: ILocation = {
						...startLocation,
						nameAndAddress:
							startLocation &&
							`${startLocation.name} - ${startLocation.address}`,
					};

					const end: ILocation = {
						...endLocation,
						nameAndAddress:
							endLocation && `${endLocation.name} - ${endLocation.address}`,
					};

					state.job = { ...jobDetails, startLocation: start, endLocation: end };
					state.loading.job = false;
				},
			)
			.addCase(
				fetchJobStatusAction.fulfilled,
				(state: JobsState, action: PayloadAction<IJobDetails>) => {
					if (state.job) {
						state.job.status = action.payload.status;
						state.loading.status = false;
					}
				},
			)
			.addCase(
				createJobAction.fulfilled,
				(state: JobsState, action: PayloadAction<IJobResponseDto>) => {
					state.data = [action.payload, ...state.data];
					state.loading.grid = false;
					state.total += 1;
				},
			)
			.addCase(
				updateJobAction.fulfilled,
				(state: JobsState, action: PayloadAction<IJobDetails>) => {
					state.job = action.payload as IJobDetails;
					state.loading.grid = false;
				},
			)
			.addCase(
				duplicateJobAction.fulfilled,
				(state: JobsState, action: PayloadAction<IJobResponseDto>) => {
					state.data = [action.payload, ...state.data];
					state.loading.grid = false;
					state.total += 1;
				},
			)
			.addCase(
				changeJobStatusAction.fulfilled,
				(
					state: JobsState,
					action: PayloadAction<{ id: number; status: JobStatus }>,
				) => {
					const { id, status } = action.payload;
					if (state.job) {
						state.loading.job = false;
						state.job.status = status;
					} else {
						if (
							status === JobStatus.READY_FOR_INVOICE ||
							status === JobStatus.READY_FOR_REINVOICE
						) {
							state.data = [...state.data.filter((item) => item.id !== id)];
							state.total -= 1;
						} else {
							const index = state.data.findIndex((item) => item.id === id);
							const job = state.data[index];
							if (job != null) job.status = status;
						}
						state.loading.grid = false;
					}
				},
			)
			.addCase(
				pauseJobAction.fulfilled,
				(state: JobsState, action: PayloadAction<number>) => {
					if (state.job) {
						state.job.status = JobStatus.PAUSED;
						state.loading.job = false;
					} else {
						state.loading.grid = false;
						const index = state.data.findIndex(
							(item) => item.id === action.payload,
						);
						const job = state.data[index];
						if (job != null) job.status = JobStatus.PAUSED;
					}
				},
			)
			.addCase(
				resumeJobAction.fulfilled,
				(state: JobsState, action: PayloadAction<number>) => {
					if (state.job) {
						state.loading.job = false;
						state.job.status = JobStatus.PLANNED;
					} else {
						state.loading.grid = false;
						const index = state.data.findIndex(
							(item) => item.id === action.payload,
						);
						const job = state.data[index];
						if (job != null) job.status = JobStatus.PLANNED;
					}
				},
			)
			.addCase(
				cancelJobAction.fulfilled,
				(state: JobsState, action: PayloadAction<number>) => {
					if (state.job) {
						state.loading.job = false;
						state.job.status = JobStatus.CANCELLED;
					} else {
						state.loading.grid = false;
						const index = state.data.findIndex(
							(item) => item.id === action.payload,
						);
						const job = state.data[index];
						if (job != null) job.status = JobStatus.CANCELLED;
						state.data = [
							...state.data.filter((item) => item.id !== action.payload),
						];
					}
				},
			)
			.addCase(
				deleteJobAction.fulfilled,
				(state: JobsState, action: PayloadAction<number | null>) => {
					state.loading.grid = false;
					state.data = [
						...state.data.filter((item) => item.id !== action.payload),
					];
				},
			)
			.addCase(forceCompleteAction.fulfilled, (state: JobsState) => {
				if (state.job) {
					state.loading.job = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(
				generateFileFrontAction.fulfilled,
				(state: JobsState, action: PayloadAction<Blob>) => {
					if (state.job) {
						state.loading.job = false;
					} else {
						state.loading.grid = false;
					}
					const data = action.payload;
					window.open(URL.createObjectURL(data));
				},
			)
			/** Rejected */
			.addCase(fetchJobsAction.rejected, (state: JobsState) => {
				state.loading.grid = false;
			})
			.addCase(fetchJobByIdAction.rejected, (state: JobsState) => {
				state.loading.job = false;
			})
			.addCase(fetchJobStatusAction.rejected, (state: JobsState) => {
				state.loading.status = false;
			})
			.addCase(createJobAction.rejected, (state: JobsState) => {
				state.loading.grid = false;
			})
			.addCase(updateJobAction.rejected, (state: JobsState) => {
				state.loading.grid = false;
			})
			.addCase(duplicateJobAction.rejected, (state: JobsState) => {
				state.loading.grid = false;
			})
			.addCase(changeJobStatusAction.rejected, (state: JobsState) => {
				if (state.job) {
					state.loading.job = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(resumeJobAction.rejected, (state: JobsState) => {
				if (state.job) {
					state.loading.job = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(pauseJobAction.rejected, (state: JobsState) => {
				if (state.job) {
					state.loading.job = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(cancelJobAction.rejected, (state: JobsState) => {
				if (state.job) {
					state.loading.job = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(deleteJobAction.rejected, (state: JobsState) => {
				state.loading.grid = false;
			})
			.addCase(forceCompleteAction.rejected, (state: JobsState) => {
				if (state.job) {
					state.loading.job = false;
				} else {
					state.loading.grid = false;
				}
			})
			.addCase(generateFileFrontAction.rejected, (state: JobsState) => {
				if (state.job) {
					state.loading.job = false;
				} else {
					state.loading.grid = false;
				}
			});
	},
});

/** Reducer */
export const jobsReducer = jobsSlice.reducer;

/** Actions */
export const {
	clearJobsAction,
	attachDocumentsToJobAction,
	deleteDocumentFromJobAction,
	clearJobAction,
} = jobsSlice.actions;
