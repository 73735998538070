import { useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useEffect, useRef } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { resetAttachmentToDownload } from "../libs/attachments/data-access/src/lib/attachments.slice";
import {
	clearAuthenticationAction,
	logoutAction,
} from "../libs/authentication/data-access/src/lib/authentication.slice";
import { PrivateRoutes } from "../libs/authentication/feature/src/lib/private-routes/private-routes.component";
import { fetchBusinessUnitsAction } from "../libs/business-units/data-access/src/lib/business-units.slice";
import { LIBRARIES } from "../libs/common/models/src/lib/constants/location.constants";
import { FIFTEEN_MINUTES_IN_MILISECONDS } from "../libs/common/models/src/lib/constants/time.constants";
import {
	useAppDispatch,
	useAppSelector,
} from "../libs/common/stores/src/lib/utils";
import { CelerumNavbar } from "../libs/common/ui/src/lib/components/celerum-navbar/celerum-navbar.component";
import { getRoutesBasedOnRole } from "../libs/common/utils/src/lib/helpers/authorization.helpers";
import {
	fetchCurrenciesAction,
	fetchCurrencyForCurrentBusinessUnitAction,
} from "../libs/currencies/data-access/src/lib/currencies.slice";
import { environment } from "../libs/environments/src/environment/environment";
import { fetchOrganisationByIdAction } from "../libs/organisations/data-access/src/lib/organisations.slice";
import {
	setAuthorizedRoutesAction,
	setIsMapLoaded,
	setNavbarExpandedAction,
} from "../libs/user-interface/data-access/src/lib/user-interface.slice";
import { fetchRolesAction } from "../libs/users/data-access/src/lib/users.slice";
import { BusinessUnitBanksPage } from "../new/BusinessUnitBankPage";
import { BusinessUnitSettingsPage } from "../new/BusinessUnitSettingsPage";
import { BusinessUnitsPage } from "../new/BusinessUnitsPage";
import { ConstraintsPage2 } from "../new/ConstraintsPage";
import { CustomersPage2 } from "../new/CustomersPage";
import { DriversPage2 } from "../new/DriversPage";
import { ExchangeRatesPage2 } from "../new/ExchangeRates";
import { InvoicingCheckPage2 } from "../new/InvoicingCheckPage";
import { InvoicingPage2 } from "../new/InvoicingPage";
import { JobTypesPage2 } from "../new/JobTypesPage";
import { JobsPage2 } from "../new/JobsPage";
import { LegsPage2 } from "../new/LegsPage";
import { LoadTypesPage2 } from "../new/LoadTypesPage";
import { LoadsPage2 } from "../new/LoadsPage";
import { LocationPage2 } from "../new/LocationsPage";
import { OrganisationsPage } from "../new/OrganisationsPage";
import { SearchPage } from "../new/SearchPage";
import { SubcontractorsPage2 } from "../new/SubcontractorsPage";
import { SupplierInvoicesPage2 } from "../new/SupplierInvoicesPage";
import { TrailerTypesPage2 } from "../new/TrailerTypesPage";
import { TrailersPage2 } from "../new/TrailersPage";
import { TruckTypesPage2 } from "../new/TruckTypesPage";
import { TrucksPage2 } from "../new/TrucksPage";
import { UsersPage2 } from "../new/UsersPage";
import { useNotifyUpdate, useTracker } from "../new/helpers";
import { CustomErrorBoundary } from "./components/custom-error-boundary.component";
import { CelerumNotificationGroup } from "./components/notification.component";
import { AccountConfirmationPage } from "./pages/account-confirmation/account-confirmation.page";
import { BusinessUnitSelectionPage } from "./pages/business-unit-selection/business-unit-selection.page";
import { ConstraintsPage } from "./pages/constraints/constraints.page";
import { CurrencyExchangeRatesPage } from "./pages/currency-exchange-rates-page/currency-exchange-rates.page";
import { CustomersPage } from "./pages/customers/customers.page";
import { DriverDetailsPage } from "./pages/driver-details/driver-details.page";
import { DriversPage } from "./pages/drivers/drivers.page";
import { ForgotPasswordPage } from "./pages/forgot-password/forgot-password.page";
import { HomePage } from "./pages/home/home.page";
import { InvoiceDetailsPage } from "./pages/invoice-details/invoice-details.page";
import { InvoicesPage } from "./pages/invoices/invoices.page";
import { InvoicingCheckPage } from "./pages/invoicing-check/invoicing-check.page";
import { JobDetailsPage } from "./pages/job-details/job-details.page";
import { JobsPage } from "./pages/jobs/jobs.page";
import { LegsPage } from "./pages/legs/legs.page";
import { LoadDetailsPage } from "./pages/load-details/load-details.page";
import { LoadGoodsPage } from "./pages/load-goods/load-goods.page";
import { LoadTypesPage } from "./pages/load-types/load-types.page";
import { LoadsPage } from "./pages/loads/loads.page";
import { LocationsPage } from "./pages/locations/locations.page";
import { LoginPage } from "./pages/login/login.page";
import { NotFoundPage } from "./pages/not-found/not-found.page";
import { ResetPasswordPage } from "./pages/reset-password/reset-password.page";
import { SubcontractorsPage } from "./pages/subcontractors/subcontractors.page";
import { SupplierInvoicesPage } from "./pages/supplier-invoices/supplier-invoices.page";
import { TrailerTypesPage } from "./pages/trailer-types/trailer-types.page";
import { TrailersPage } from "./pages/trailers/trailers.page";
import { TruckTypesPage } from "./pages/truck-types/truck-types.page";
import { TrucksPage } from "./pages/trucks/trucks.page";
import { UsersPage } from "./pages/users/users-page";

export const App = () => {
	useTracker();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	useNotifyUpdate();
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: environment.googleMapsApiKey,
		libraries: LIBRARIES,
	});

	const {
		authentication: {
			token,
			currentBusinessUnit,
			businessUnits,
			currentUser,
			expiryTokenValue,
		},
		attachments: { currentAttachmentUrlToDownload },
		userInterFace: { navbarExpanded },
		businessUnits: { data: detailedBusinessUnits },
	} = useAppSelector((state) => ({
		authentication: state.authentication,
		attachments: state.attachments,
		userInterFace: state.userInterface,
		businessUnits: state.businessUnits,
	}));

	const interval = useRef<ReturnType<typeof setInterval> | null>(null);

	const logoutHandler = () => {
		dispatch(logoutAction());
		dispatch(clearAuthenticationAction());
		navigate("/login");
	};

	const handleClickOnBusinessUnit = useCallback(() => {
		if (businessUnits && businessUnits.length > 1) {
			navigate("/business-unit-selection", {
				state: { from: window.location.pathname },
			});
		}
	}, [businessUnits, navigate]);

	const handleNavbarExpanded = (expanded: boolean) => {
		dispatch(setNavbarExpandedAction(expanded));
	};

	useEffect(() => {
		if (token && detailedBusinessUnits?.length && currentBusinessUnit) {
			const currentBusinessUnitDetail = detailedBusinessUnits.find(
				(x) => x.id === currentBusinessUnit.id,
			);

			if (currentBusinessUnitDetail) {
				dispatch(
					fetchOrganisationByIdAction(currentBusinessUnitDetail.organisationId),
				);
			}
		}
	}, [currentBusinessUnit, detailedBusinessUnits, dispatch, token]);

	useEffect(() => {
		if (expiryTokenValue) {
			interval.current = setInterval(async () => {
				if (Date.now() >= expiryTokenValue * 1000) {
					const actionResult = await dispatch(logoutAction());
					if (logoutAction.fulfilled.match(actionResult)) {
						dispatch(clearAuthenticationAction());
					}
				}
			}, FIFTEEN_MINUTES_IN_MILISECONDS);
		}

		return () => {
			if (interval.current) {
				clearInterval(interval.current);
			}
		};
	}, [expiryTokenValue, dispatch]);

	/** Global attachment download logic. */
	useEffect(() => {
		if (currentAttachmentUrlToDownload) {
			const link = document.createElement("a");
			link.href = currentAttachmentUrlToDownload;
			link.click();
		}

		return () => {
			dispatch(resetAttachmentToDownload());
		};
	}, [currentAttachmentUrlToDownload, dispatch]);

	useEffect(() => {
		setTimeout(() => {
			if (token && currentBusinessUnit) {
				dispatch(fetchCurrenciesAction());
				dispatch(fetchCurrencyForCurrentBusinessUnitAction());
				dispatch(fetchRolesAction());
				dispatch(fetchBusinessUnitsAction({}));
			}
		}, 100);
	}, [token, currentBusinessUnit, dispatch]);

	useEffect(() => {
		if (currentUser?.roles) {
			const routes = getRoutesBasedOnRole(currentUser.roles[0]?.name)
				.map((userRoute) =>
					userRoute.route ? userRoute.route.split("/")[1] : "",
				)
				.filter((x) => x) as string[];
			routes.push("");
			routes.push("business-unit-selection");
			dispatch(setAuthorizedRoutesAction(routes));
		}
	}, [currentUser, dispatch]);

	useEffect(() => {
		dispatch(setIsMapLoaded(isLoaded));
	}, [isLoaded, dispatch]);

	return (
		<>
			<ToastContainer />
			<Routes>
				<Route path="/login" element={<LoginPage />} />
				<Route path="/forgotten-password" element={<ForgotPasswordPage />} />
				<Route path="/reset-password" element={<ResetPasswordPage />} />
				<Route
					path="/account-confirmation"
					element={<AccountConfirmationPage />}
				/>
				<Route
					element={
						<CelerumNavbar
							navbarExpanded={navbarExpanded}
							routes={getRoutesBasedOnRole(currentUser?.roles?.[0]?.name)}
							handleNavbarExpanded={handleNavbarExpanded}
							handleClickOnBusinessUnit={handleClickOnBusinessUnit}
							logoutHandler={logoutHandler}
							userProfile={{
								firstName: currentUser ? currentUser.firstName : "",
								lastName: currentUser ? currentUser.lastName : "",
								businessUnitName: currentBusinessUnit
									? currentBusinessUnit.name
									: "Unselected",
							}}
						>
							<CustomErrorBoundary>
								<PrivateRoutes token={token} />
							</CustomErrorBoundary>
						</CelerumNavbar>
					}
				>
					<Route path="*" element={<NotFoundPage />} />
					<Route path="/" element={<HomePage />} />
					<Route path="/jobs" element={<JobsPage />} />
					<Route path="/jobs/:id" element={<JobDetailsPage />} />
					<Route path="/legs" element={<LegsPage />} />
					<Route path="/legs2" element={<LegsPage2 />} />
					<Route path="/jobs2" element={<JobsPage2 />} />
					<Route path="/loads2" element={<LoadsPage2 />} />
					<Route path="/loads" element={<LoadsPage />} />
					<Route path="/search" element={<SearchPage />} />
					<Route path="/loads/:id" element={<LoadDetailsPage />} />
					<Route path="/loads/:id/goods" element={<LoadGoodsPage />} />
					<Route path="/invoicing-check" element={<InvoicingCheckPage />} />
					<Route path="/invoicing-check/:id" element={<JobDetailsPage />} />
					<Route path="/supplier-invoices" element={<SupplierInvoicesPage />} />
					<Route
						path="/supplier-invoices/:id"
						element={<InvoiceDetailsPage />}
					/>
					<Route path="/invoices" element={<InvoicesPage />} />
					<Route path="/invoices/:id" element={<InvoiceDetailsPage />} />
					<Route path="/trucks" element={<TrucksPage />} />
					<Route path="/trailers" element={<TrailersPage />} />
					<Route path="/drivers" element={<DriversPage />} />
					<Route path="/drivers2" element={<DriversPage2 />} />
					<Route path="/trucks2" element={<TrucksPage2 />} />
					<Route path="/invoicingcheck2" element={<InvoicingCheckPage2 />} />
					<Route path="/invoicing2" element={<InvoicingPage2 />} />
					<Route path="/trailers2" element={<TrailersPage2 />} />
					<Route path="/subcontractors2" element={<SubcontractorsPage2 />} />
					<Route path="/customers2" element={<CustomersPage2 />} />
					<Route path="/locations2" element={<LocationPage2 />} />
					<Route path="/trucktypes2" element={<TruckTypesPage2 />} />
					<Route path="/trailertypes2" element={<TrailerTypesPage2 />} />
					<Route path="/constraints2" element={<ConstraintsPage2 />} />
					<Route path="/loadtypes2" element={<LoadTypesPage2 />} />
					<Route path="/exchangerates2" element={<ExchangeRatesPage2 />} />
					<Route path="/jobtypes2" element={<JobTypesPage2 />} />
					<Route path="/jobtypes2" element={<JobTypesPage2 />} />
					<Route
						path="/supplierinvoices2"
						element={<SupplierInvoicesPage2 />}
					/>

					<Route path="/drivers/:id" element={<DriverDetailsPage />} />
					<Route path="/customers" element={<CustomersPage />} />
					<Route path="/truck-types" element={<TruckTypesPage />} />
					<Route path="/trailer-types" element={<TrailerTypesPage />} />
					<Route path="/constraints" element={<ConstraintsPage />} />
					<Route path="/locations" element={<LocationsPage />} />
					<Route path="/subcontractors" element={<SubcontractorsPage />} />
					<Route path="/users" element={<UsersPage />} />
					<Route path="/load-types" element={<LoadTypesPage />} />
					<Route
						path="/exchange-rates"
						element={<CurrencyExchangeRatesPage />}
					/>
					<Route path="/organisations" element={<OrganisationsPage />} />
					<Route path="/business-units" element={<BusinessUnitsPage />} />
					<Route
						path="/business-unit-settings"
						element={<BusinessUnitSettingsPage />}
					/>
					<Route
						path="/business-unit-banks"
						element={<BusinessUnitBanksPage />}
					/>
					<Route path="/users2" element={<UsersPage2 />} />
				</Route>
				{/* Private routes without navbar. */}
				<Route element={<PrivateRoutes token={token} />}>
					<Route
						path="/business-unit-selection"
						element={
							<BusinessUnitSelectionPage logoutHandler={logoutHandler} />
						}
					/>
				</Route>
			</Routes>
			<CelerumNotificationGroup />
		</>
	);
};
